import { HubLayoutWithToolMenu } from "@/components/layouts/HubLayout";
import { getConfig } from "@/features/branding";
import type { NextPageWithLayout } from "@/pages/_app";
const Home: NextPageWithLayout = () => {
  const HomeDashboard = getConfig().pages.home;
  return <HomeDashboard data-sentry-element="HomeDashboard" data-sentry-component="Home" data-sentry-source-file="index.tsx" />;
};
Home.getLayout = page => {
  return <HubLayoutWithToolMenu>{page}</HubLayoutWithToolMenu>;
};
export default Home;